// import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
// import PostsReducer from './reducers/PostsReducer';
// import thunk from 'redux-thunk';
// import { AuthReducer } from './reducers/AuthReducer';
// import todoReducers from './reducers/Reducers';
// //import { reducer as reduxFormReducer } from 'redux-form';
// const middleware = applyMiddleware(thunk);

// const composeEnhancers =
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const reducers = combineReducers({
//     posts: PostsReducer,
//     auth: AuthReducer,
// 		todoReducers,
// 	//form: reduxFormReducer,

// });

// //const store = createStore(rootReducers);

// export const store = createStore(reducers,  composeEnhancers(middleware));

import { configureStore } from "@reduxjs/toolkit";
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import { rootPersistConfig, rootReducer } from "./rootReducer";

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

const persistor = persistStore(store);

const { dispatch } = store;

const useSelector = useAppSelector;

const useDispatch = () => useAppDispatch();

export { store, persistor, dispatch, useSelector, useDispatch };
