import jwtDecode from "jwt-decode";
// import { verify, sign } from 'jsonwebtoken';
//
import axios from "./axios";

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (
    !accessToken ||
    accessToken === "undefined" ||
    accessToken === undefined
  ) {
    return false;
  }
  // ----------------------------------------------------------------------

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;
  console.log(timeLeft);
  expiredTimer = window.setTimeout(() => {
    console.log("expired");
    // You can do what ever you want here, like show a notification
  }, timeLeft);
};

// ----------------------------------------------------------------------

const setSession = (accessToken) => {
  console.log("accessToken: ", accessToken);
  if (accessToken) {
    localStorage.setItem("adjusterUAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken);
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem("adjusterUAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const removeSession = () => {
  setSession("");
};

const checkLogin = () => {
  if (isValidToken(localStorage.getItem("adjusterUAccessToken"))) {
    return true;
  } else {
    return false;
  }
};

const getAccessToken = () => {
  return localStorage.getItem("adjusterUAccessToken");
};

export { isValidToken, setSession, removeSession, checkLogin, getAccessToken };
