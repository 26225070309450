import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @react-bootstrap
import Form from "react-bootstrap/Form";

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default function RHFTextField({ name, label, ...other }) {
  const { control } = useFormContext();

  return (
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
      <Form.Label>
        <strong>{label}</strong>
      </Form.Label>

      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <Form.Control
              {...field}
              isInvalid={!!error}
              type="text"
              placeholder={label}
              {...other}
            />
            <Form.Control.Feedback type="invalid">
              {error?.message}
            </Form.Control.Feedback>
          </>
        )}
      />
    </Form.Group>
  );
}
