import React, { useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";


const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo">
        {background.value === "dark" || navigationHader !== "color_1" ? (
			<>
				<svg  className="logo-abbr" width="35" height="35" viewBox="0 0 50 53" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path className="line-2" d="M0 25C0 20.3801 1.28016 15.8505 3.69839 11.9141C6.11663 7.97761 9.57835 4.78822 13.6993 2.69989C17.8203 0.611568 22.4394 -0.294006 27.0438 0.0836832C31.6483 0.461372 36.058 2.10755 39.7836 4.8395L33.2788 13.7101C31.1925 12.1802 28.723 11.2584 26.1445 11.0469C23.566 10.8354 20.9794 11.3425 18.6716 12.5119C16.3639 13.6814 14.4253 15.4675 13.0711 17.6719C11.7169 19.8763 11 22.4128 11 25H0Z" fill="#374557"/>
					<path className="line-2" d="M50 28C50 34.6304 47.3661 40.9893 42.6777 45.6777C37.9893 50.3661 31.6304 53 25 53C18.3696 53 12.0107 50.3661 7.32233 45.6777C2.63392 40.9893 1.00116e-06 34.6304 0 28L11 28C11 31.713 12.475 35.274 15.1005 37.8995C17.726 40.525 21.287 42 25 42C28.713 42 32.274 40.525 34.8995 37.8995C37.525 35.274 39 31.713 39 28H50Z" fill="var(--primary)"/>
					<path className="line-2" d="M25 28H50L37 47L25 28Z" fill="var(--primary)"/>
				</svg>
	  		AdjusterU
			</>
        ) : (
			<>
				<svg  className="logo-abbr" width="35" height="35" viewBox="0 0 50 53" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path className="line-2" d="M0 25C0 20.3801 1.28016 15.8505 3.69839 11.9141C6.11663 7.97761 9.57835 4.78822 13.6993 2.69989C17.8203 0.611568 22.4394 -0.294006 27.0438 0.0836832C31.6483 0.461372 36.058 2.10755 39.7836 4.8395L33.2788 13.7101C31.1925 12.1802 28.723 11.2584 26.1445 11.0469C23.566 10.8354 20.9794 11.3425 18.6716 12.5119C16.3639 13.6814 14.4253 15.4675 13.0711 17.6719C11.7169 19.8763 11 22.4128 11 25H0Z" fill="#374557"/>
					<path className="line-2" d="M50 28C50 34.6304 47.3661 40.9893 42.6777 45.6777C37.9893 50.3661 31.6304 53 25 53C18.3696 53 12.0107 50.3661 7.32233 45.6777C2.63392 40.9893 1.00116e-06 34.6304 0 28L11 28C11 31.713 12.475 35.274 15.1005 37.8995C17.726 40.525 21.287 42 25 42C28.713 42 32.274 40.525 34.8995 37.8995C37.525 35.274 39 31.713 39 28H50Z" fill="var(--primary)"/>
					<path className="line-2" d="M25 28H50L37 47L25 28Z" fill="var(--primary)"/>
				</svg>
AdjusterU
			</>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
