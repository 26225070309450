function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_HOME = "/";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  forgotPassword: path(ROOTS_AUTH, "/forgot-password"),
  resetPasswordConfirm: path(
    ROOTS_AUTH,
    "/password-reset-confirm/:uid/:token/"
  ),
  verify: path(ROOTS_AUTH, "/verify"),
};

export const PATH_HOME = {
  root: ROOTS_HOME,
  dashboard: path(ROOTS_HOME, ""),
};
