import { useEffect, Suspense } from "react";

/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

import { useSelector, useDispatch } from "./redux/store";
import { loadUser } from "./redux/slices/user";
import { checkLogin } from "./utils/jwt";
import Router from "./routes";

export default function App(props) {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    if (checkLogin() && !isAuthenticated) {
      console.log("loading logged in user");
      dispatch(loadUser());
    }
  }, [dispatch]);

  return (
    <Suspense
      fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }
    >
      <Router />
    </Suspense>
  );
}
