import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

// forms
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";

import { useSelector, useDispatch } from "../../redux/store";
import { loginUser } from "../../redux/slices/user";
import Alert from "react-bootstrap/Alert";
import { FormProvider, RHFTextField } from "../components/hook-form";
//

import logo from "../../images/log.png";
import logofull from "../../images/logo-full.png";

import { PATH_AUTH, PATH_HOME } from "../../routes/paths";

export default function Login(props) {
  const dispatch = useDispatch();
  const { errors, isAuthenticated } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const defaultValues = useMemo(() => ({
    email: "admin@example.com",
    password: "Later!23",
  }));

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    control,
    watch,
    setError,
    formState: { isSubmitting },
  } = methods;

  const onLogin = async (data) => {
    // e.preventDefault();
    console.log("login", data);
    return dispatch(loginUser(data));
  };

  useEffect(() => {
    console.log("redirecting to home: ", isAuthenticated);
    if (isAuthenticated) {
      navigate("/", { replace: true });
      // <Navigate to="/" replace={true} />;
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="login-main-page">
      <div className="login-wrapper">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-contain-center">
            <div className="col-xl-12 mt-3">
              <div className="card">
                <div className="card-body p-0">
                  <div className="row m-0">
                    <div className="col-xl-6 col-md-6 sign text-center">
                      <div>
                        <div className="text-center my-5">
                          <Link to={PATH_HOME.root}>
                            <img width="200" src={logofull} alt="" />
                          </Link>
                        </div>
                        <img src={logo} className="education-img" alt="" />
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                      <div className="sign-in-your">
                        <h4 className="fs-20 font-w800 text-black">
                          Sign in your account
                        </h4>
                        <span>
                          Welcome back! Login with your data that you entered
                          <br /> during registration
                        </span>
                        <div className="login-social d-none">
                          <Link to={"#"} className="btn font-w800 d-block my-4">
                            <i className="fab fa-google me-2 text-primary"></i>
                            Login with Google
                          </Link>
                          <Link to={"#"} className="btn font-w800 d-block my-4">
                            <i className="fab fa-facebook-f me-2 facebook-log"></i>
                            Login with Facebook
                          </Link>
                        </div>

                        <div className="mt-4">
                          {/* Print error messages */}
                          {errors &&
                            Object.keys(errors).map((error) => (
                              <Alert key={error} variant="danger">
                                {errors[error][0]}
                              </Alert>
                            ))}
                        </div>

                        {props.successMessage && (
                          <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                            {props.successMessage}
                          </div>
                        )}

                        <FormProvider
                          methods={methods}
                          onSubmit={handleSubmit(onLogin)}
                        >
                          <div className="mt-4">
                            <RHFTextField name="email" label="Email Address" />
                          </div>

                          <RHFTextField
                            type="password"
                            name="password"
                            label="Password"
                          />
                          <div className="row d-flex justify-content-end mb-2">
                            <div className="mb-3 d-inline text-end">
                              <Link to={PATH_AUTH.forgotPassword}>
                                Forgot Password
                              </Link>
                            </div>
                          </div>
                          <div className="d-grid gap-2">
                            <Button
                              type="submit"
                              variant="primary"
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? "Verifying..." : "Sign In"}
                            </Button>
                          </div>
                        </FormProvider>
                        <div className="new-account mt-3">
                          <p className="">
                            Don't have an account?{" "}
                            <Link
                              className="text-primary"
                              to={PATH_AUTH.register}
                            >
                              Register
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
