import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

import { useSelector } from "../redux/store";
// routes
import { PATH_HOME } from "../routes/paths";

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated } = useSelector((state) => state.user);

  if (isAuthenticated) {
    return <Navigate to={PATH_HOME.root} />;
  }

  return <>{children}</>;
}
