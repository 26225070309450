import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import DashboardLayout from "../jsx/layouts/DashboardLayout";

// components
import LoadingScreen from "../jsx/components/LoadingScreen";

import AuthGuard from "../guards/AuthGuard";
import GuestGuard from "../guards/GuestGuard";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard>
              <Home />
            </AuthGuard>
          ),
          index: true,
        },
      ],
    },
    {
      path: "/auth",
      element: <DashboardLayout />,
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: "forgot-password",
          element: (
            <GuestGuard>
              <ForgotPassword />
            </GuestGuard>
          ),
        },
        {
          path: "password-reset-confirm/:uid/:token",
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: "*",
      element: <DashboardLayout />,
      children: [
        { path: "404", element: <Error404 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// Auth
const Home = Loadable(lazy(() => import("../jsx/components/Dashboard/Home")));
const Login = Loadable(lazy(() => import("../jsx/pages/Login")));
const Register = Loadable(lazy(() => import("../jsx/pages/Register")));
const ForgotPassword = Loadable(
  lazy(() => import("../jsx/pages/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../jsx/pages/ResetPassword"))
);
const Error400 = Loadable(lazy(() => import("../jsx/pages/Error400")));
const Error403 = Loadable(lazy(() => import("../jsx/pages/Error403")));
const Error404 = Loadable(lazy(() => import("../jsx/pages/Error404")));
const Error500 = Loadable(lazy(() => import("../jsx/pages/Error500")));
const Error503 = Loadable(lazy(() => import("../jsx/pages/Error503")));
