import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";

/// Layout
import Nav from "./nav";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";

export default function DashboardLayout() {
  const { menuToggle } = useContext(ThemeContext);
  let path = window.location.pathname;
  path = path.split("/");
  const currentPath = path[path.length - 1];

  //   The list of path those don't need layout
  console.log(currentPath, path);
  let pagePath =
    ["login", "404", "register", "forgot-password"].includes(currentPath) ||
    path.includes("password-reset-confirm");

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div
          className={`${!pagePath ? "content-body" : ""}`}
          style={{ minHeight: window.screen.height - 45 }}
        >
          <div className={`${!pagePath ? "container-fluid" : ""}`}>
            <Outlet />
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
}
