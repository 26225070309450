import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import userReducer from "./slices/user";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const userPersistConfig = {
  key: "adjusteru_user",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "filters"],
};

const rootReducer = combineReducers({
  // mail: mailReducer,
  // chat: chatReducer,
  user: userReducer,
  // user: persistReducer(userPersistConfig, userReducer),
});

export { rootPersistConfig, rootReducer };
