import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { dispatch } from "../store";
import { setSession, getAccessToken, removeSession } from "../../utils/jwt";

import { AUTH_API } from "../../constants/ApiPaths";

const initialState = {
  // States for User
  isAuthenticated: false,
  authenticatingUser: false,
  user: {},
  errors: {},
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // User Authentication
    startAuthentication(state) {
      state.authenticatingUser = true;
    },
    userAuthenticationSuccess(state, action) {
      state.authenticatingUser = false;
      state.errors = {};
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    userAuthenticationFailed(state, action) {
      state.authenticatingUser = false;
      state.isAuthenticated = false;
      state.errors = action.payload;
    },
    userAuthenticationLogout(state, action) {
      state.authenticatingUser = false;
      state.errors = {};
      state.user = {};
      state.isAuthenticated = false;
    },
  },
});

// Reducers
export default slice.reducer;

// Actions
export const { userAuthenticationSuccess } = slice.actions;

// ------------------------------------------------

export function loginUser(data) {
  // Login user
  return (dispatch) => {
    dispatch(slice.actions.startAuthentication());

    return axios
      .post(AUTH_API.LOGIN, data)
      .then((response) => {
        console.log("Login: ", response);
        setSession(response.data.access_token);
        dispatch(slice.actions.userAuthenticationSuccess(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(slice.actions.userAuthenticationFailed(error));
      });
  };
}

export function loadUser() {
  // Loading user
  return (dispatch) => {
    dispatch(slice.actions.startAuthentication());
    axios.defaults.headers.common.Authorization = `Bearer ${getAccessToken()}`;
    axios
      .get(AUTH_API.USER_DETAIL)
      .then((response) => {
        dispatch(slice.actions.userAuthenticationSuccess(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(slice.actions.userAuthenticationFailed(error));
      });
  };
}

export function logoutUser() {
  // Loading user
  return (dispatch) => {
    dispatch(slice.actions.startAuthentication());
    axios
      .post(AUTH_API.LOGOUT)
      .then((response) => {
        removeSession();
        dispatch(slice.actions.userAuthenticationLogout());
      })
      .catch((error) => {
        console.log(error);
        dispatch(slice.actions.userAuthenticationFailed(error));
      });
  };
}
